import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import PdfDecokatsu from "../../assets/docs/decokatsu_20241007.pdf";
// import { pdfjs } from 'react-pdf';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Styles
import "./styles.scss";

import { pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@2.9.359/build/pdf.worker.min.js`;


// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.7.76/build/pdf.worker.min.mjs`;

export default function ModalDecokatsu() {
    const [numPaginas, setNumPaginas] = useState(null);
    const [numerosPaginas, setNumerosPaginas] = useState([]);
    const [imagens, setImagens] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(0);

    useEffect(() => {
        const carregamento = pdfjs.getDocument(PdfDecokatsu);
        carregamento.promise.then(pdf => {
            setNumPaginas(pdf.numPages);
            const paginas = Array.from({ length: pdf.numPages }, (_, i) => i + 1);
            setNumerosPaginas(paginas);

            // Gerar as imagens
            Promise.all(paginas.map((numeroPagina) => renderizarPagina(pdf, numeroPagina)))
                .then(setImagens);
        });
    }, []);


    // Renderiza a página
    const renderizarPagina = async (pdf, numeroPagina) => {
        const pagina = await pdf.getPage(numeroPagina);
        const viewport = pagina.getViewport({ scale: 2 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const contextoRenderizacao = {
            canvasContext: context,
            viewport: viewport,
        };
        await pagina.render(contextoRenderizacao).promise; // Aguardar a renderização

        return canvas.toDataURL(); // Retornar a imagem
    };


    // Atualizar a página atual
    const atualizarPagina = (_, newIndex) => {
        setPaginaAtual(newIndex); 
    };

    return (
        <div className="modal-content">
            <div className="modal-header d-flex justify-content-center">
                <h5 className="modal-title text-center">Decokatsu</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <h5><b>x</b></h5>
                </button>
            </div>
            <div className='img-modal-decokatsu'>
                <Slider dots={true} infinite={false} beforeChange={atualizarPagina} arrows={false}>
                    {imagens.map((imagem, index) => (
                        <div key={index} className='d-flex justify-content-center container-img-decokatsu'>
                            <img src={imagem} alt={`Página ${numerosPaginas[index]}`} />
                        </div>
                    ))}
                </Slider>
                <div className="text-center mt-5">
                    <h6>
                        Página {paginaAtual + 1} de {numPaginas}
                    </h6>
                </div>
            </div>
        </div>
    );
}
